import { render, staticRenderFns } from "./query-rank.vue?vue&type=template&id=bc7c08ba&scoped=true&"
import script from "./query-rank.vue?vue&type=script&lang=js&"
export * from "./query-rank.vue?vue&type=script&lang=js&"
import style0 from "./query-rank.vue?vue&type=style&index=0&id=bc7c08ba&scoped=true&lang=less&"
import style1 from "./query-rank.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7c08ba",
  null
  
)

export default component.exports